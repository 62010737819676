import { Component, OnInit, Inject, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, FormControl, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Unsubscriber } from '../../../core/unsubscriber/unsubscriber';
import { EMailType, IEmailMessage } from '../../models/email.model';
import { SubscribeBrfMappenModel } from '../../models/subscribe-brf-mappen.models';
import { MailService } from '../../services/email.service';
import { SnackbarService } from '../../services/snackbar.service';
import { SubscribeService } from '../../services/subscribe.service';

@Component({
  selector: 'brf-subscribe-dialog',
  templateUrl: './subscribe-dialog.component.html',
  styleUrls: ['./subscribe-dialog.component.scss']
})
export class SubscribeDialogComponent extends Unsubscriber implements OnInit {
  form: UntypedFormGroup;
  inputEmail = '';
  state = 'main';

  constructor(
    public dialogRef: MatDialogRef<SubscribeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private mailService: MailService,
    private snackbarService: SnackbarService,
    private subscribeService: SubscribeService
  ) {
    super();
    this.inputEmail = data.email;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      tenantName: ['', [Validators.required]],
      county: ['', [Validators.required]],
      name: [''],
      email: ['', [Validators.required, Validators.email]],
      message: [''],
      selectedMalardalen: [false, []],
      selectedVastraGotaland: [false, []],
      selectedSkane: [false, []],
      token: ['', [Validators.required]],
      acceptTerm: [false, [Validators.requiredTrue]]
    });

    if (this.inputEmail && this.inputEmail.length > 0) {
      this.form.controls['email'].setValue(this.inputEmail);
    }
  }

  close() {
    this.dialogRef.close();
  }

  onSubmit() {
    console.log(this.form.controls);
    let model: SubscribeBrfMappenModel = {
      tenantName: this.form.controls['tenantName'].value,
      county: this.form.controls['county'].value,
      name: this.form.controls['name'].value,
      email: this.form.controls['email'].value,
      message: this.form.controls['message'].value,
      selectedMalardalen: this.form.controls['selectedMalardalen'].value,
      selectedVastraGotaland: this.form.controls['selectedVastraGotaland'].value,
      selectedSkane: this.form.controls['selectedSkane'].value,
      token: this.form.controls['token'].value
    };
    this.addSubscription(
      this.subscribeService.subscribeBrfMappenContact(model).subscribe(
        (result: boolean) => {
          var mail: IEmailMessage = {
            emailType: EMailType.BRFSubscribe,
            dataPayload: JSON.stringify(model)
          };

          this.mailService.sendEmailWithType(mail).subscribe(
            result => {
              if (result.message == 'i18nMsg.EmailPushSuccess') {
                this.snackbarService.displayMessage('Din förfrågan har skickats!');
                this.state = 'success';
              }
            },
            error => {
              this.snackbarService.displayMessage('Kan inte skicka meddelande, försök senare!');
            }
          );
        },
        error => {
          this.snackbarService.displayMessage('Kan inte skicka meddelande, försök senare!');
        }
      )
    );
  }

  isFormValid() {
    return (
      this.form.valid && (this.form.controls['selectedMalardalen'].value == true || this.form.controls['selectedVastraGotaland'].value == true || this.form.controls['selectedSkane'].value == true)
    );
  }

  onSubmitClick(): void {
    this.onSubmit();
  }
}
