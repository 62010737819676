import { EEntityType } from './entity-type.enum';

export interface IEmailMessage {
  subject?: string;
  content?: string;
  toAddresses?: IEmailAddress[];
  fromAddresses?: IEmailAddress[];
  cc?: IEmailAddress[];
  emailType?: EMailType;
  dataPayload?: string;
  locale?: string;
  file?: any;
  filename?: string;
}

export interface IEmailAddress {
  name: string;
  address: string;
}

export interface EmailMessageWithEntityId {
  subject: string;
  content: string;
  file?: any;
  filename?: string;
  toIds: string[];
  fromAddress?: IEmailAddress;
  entityType: EEntityType;
  entityName?: string;
  backupToAddress?: IEmailAddress;
  ccAddress?: IEmailAddress[];
  officeId?: string;
  emailType: EMailType;
  dataPayload?: string;
}

export enum EMailType {
  General = 1,
  RFQReminder = 2,
  RegisterEmail = 3,
  ProfilePerson = 4,
  NewRfq = 5,
  InterestRfq = 6,
  ContactForm = 7,
  ProductContactForm = 8,
  AccountResetPassword = 9,
  NewRFQFromDashboard = 10,
  UpdateRFQFromDashboard = 11,
  UpdateStatusRFQFromDashboard = 12,
  EmailToSupplierNewRFQFromDashboard = 13,
  EmailToSupplierUpdateRFQFromDashboard = 14,
  CreateAccount = 15,
  NewsletterTestEmail = 16,
  ClosedStatusRFQFromDashboard = 17,
  ProfileCompany = 18,
  RemindRFQBeforeClose = 20,
  ChangedUsername = 21,

  BRFContact = 30,
  BRFSubscribe = 31,
  BRFProfileCompany = 32
}
