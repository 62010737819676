import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class BrowserService {
  public isbrowser: boolean;
  public isIE: boolean = false;
  public isMobileScreenSubject = new BehaviorSubject<boolean>(null);
  public isDesktopScreenSubject = new BehaviorSubject<boolean>(null);

  constructor(@Inject(PLATFORM_ID) private platformId: any, public platform: Platform) {
    if (isPlatformBrowser(this.platformId)) {
      this.isbrowser = true;
    } else {
      this.isbrowser = false;
    }
    this.isIE = platform.TRIDENT;
  }
}
