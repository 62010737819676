import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { SubscribeDialogComponent } from '../../../shared/modals/subscribe-dialog/subscribe-dialog.component';

@Component({
  selector: 'brf-news-newsletter-widget',
  templateUrl: './newsletter-widget.component.html',
  styleUrls: ['./newsletter-widget.component.scss']
})
export class NewsletterWidgetComponent implements OnInit {
  constructor(public dialog: MatDialog) {}
  emailControl = new UntypedFormControl();

  ngOnInit(): void {}

  submit() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      email: this.emailControl.value
    };
    this.dialog.open(SubscribeDialogComponent, dialogConfig);
  }
}
