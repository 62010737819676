import { MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition } from '@angular/material/legacy-snack-bar';
import { environment } from '../../environments/environment';

// API
export const API_BASE_URL = environment.api;
export const ENVIRONMENT_NAME = environment.name; //local|prod

// tslint:disable-next-line:max-line-length
export const EMAIL_REGEX: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const YEAR_REGEX: RegExp = /^\d{4}$/;

export const FORBIDDEN_STATUS_CODE = '403';
export const UNAUTHORIZED_STATUS_CODE = '401';
export const ENGLISH_CODE = 'en-GB';
export const SWEDISH_CODE = 'sv-SE';

export const SNACKBAR_DURATION = 2000;
export const SNACKBAR_HORIZONTAL_POSITION: MatSnackBarHorizontalPosition = 'right';

export const EMAIL_ATTACH_FILE_SIZE_LIMIT = 10;
export const EMAIL_ATTACH_FILE_TYPES_BLOCKED =
  '.ade, .adp, .apk, .appx, .appxbundle, .bat, .cab, .chm, .cmd, .com, .cpl, .dll, .dmg, .ex, .ex_, .exe, .hta, .ins, .isp, .iso, .jar, .js, .jse, .lib, .lnk, .mde, .msc, .msi, .msix, .msixbundle, .msp, .mst, .nsh, .pif, .ps1, .scr, .sct, .shb, .sys, .vb, .vbe, .vbs, .vxd, .wsc, .wsf, .wsh';

export const SPECIAL_CHARACTERS = [
  { Sign: '㎡', HtmlEntity: '&#13217;' },
  { Sign: '㎥', HtmlEntity: '&#13221;' },
  { Sign: '±', HtmlEntity: '&#177;' },
  { Sign: 'æ', HtmlEntity: '&#230;' },
  { Sign: 'µ', HtmlEntity: '&#181;' },
  { Sign: 'º', HtmlEntity: '&#186;' },
  { Sign: 'Ø', HtmlEntity: '&#216;' },
  { Sign: 'ø', HtmlEntity: '&#248;' }
];
