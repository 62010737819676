import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ServiceHelper } from '../helpers/service.helper';
import { SubscribeBrfMappenModel } from '../models/subscribe-brf-mappen.models';
import { SubscribeModel } from '../models/subscribe.model';

@Injectable({
  providedIn: 'root'
})
export class SubscribeService extends ServiceHelper {
  constructor(private http: HttpClient) {
    super();
  }

  addContact(data: SubscribeModel): Observable<boolean> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<boolean>(`${environment.api}/subscribe/addcontact`, data, { headers: headers });
  }

  subscribeBrfMappenContact(data: SubscribeBrfMappenModel): Observable<boolean> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<boolean>(`${environment.api}/subscribe/subscribebrfmappen`, data, { headers: headers });
  }
}
