<section class="news">
  <div class="container">
    <div class="row">
      <div class="col-md-8 col-lg-9 left-pane"></div>
      <div class="col-md-4 col-lg-3 right-pane">
        <!-- <div class="right-component-container">
          <brf-search-supplier></brf-search-supplier>
        </div> -->
        <div class="right-component-container resize">
          <brf-exposure-small (clickEvent)="exposureClicked($event)" [exposures300x250]="exposures300x250[0]" *ngIf="exposures300x250?.length > 0"></brf-exposure-small>
        </div>
        <div class="right-component-container">
          <brf-mappen-widget></brf-mappen-widget>
        </div>
        <div class="right-component-container">
          <brf-news-newsletter-widget></brf-news-newsletter-widget>
        </div>
        <div class="right-component-container resize">
          <brf-exposure-small (clickEvent)="exposureClicked($event)" [exposures300x250]="exposures300x250[1]" *ngIf="exposures300x250?.length > 1"></brf-exposure-small>
        </div>
      </div>
    </div>
  </div>
</section>
