import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoaderService } from '../shared/components/loader/services/loader.service';

@Injectable()
export class LoaderInterceptorService implements HttpInterceptor {
  private totalRequests = 0;
  constructor(private loaderService: LoaderService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.totalRequests++;
    this.showLoader(false);
    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.totalRequests--;
            if (this.totalRequests === 0) this.onEnd();
          }
        },
        (err: any) => {
          this.totalRequests--;
          if (this.totalRequests === 0) this.onEnd();
        }
      )
    );
  }

  private onEnd(): void {
    this.hideLoader();
  }

  private showLoader(isShowing : boolean): void {
    this.loaderService.show(isShowing);
  }

  private hideLoader(): void {
    this.loaderService.hide();
  }
}
