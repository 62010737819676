import { Injectable } from '@angular/core';
import * as CC_GLOBAL from '../../shared/shared-common-constant';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  constructor(public snckBar: MatSnackBar) {}

  public displayMessage(message: string, duration: number = 0) {
    let messageToDisplay = message;
    duration = duration == 0 ? CC_GLOBAL.SNACKBAR_DURATION : duration;
    this.snckBar.open(messageToDisplay, '', {
      duration: duration,
      horizontalPosition: CC_GLOBAL.SNACKBAR_HORIZONTAL_POSITION,
      panelClass: ['matPanelClass']
    });
  }
}
