import { SafeHtml, SafeUrl } from '@angular/platform-browser';
import { EnumNewsStatus } from '../../news/Enums/newsstatus.enum';

export enum EnumNewsType {
  TopNews = 1, //Affärer
  Economy, //Arbetsliv
  DeliveryNews, //Branschnytt
  LawsAndRules, //Jobb
  ProductNews, //HÅLLBARHET
  Partners, //ToppNyheter
  Play, //Play
  Reportage, //Reportage
  Technic, //Teknik
  Environment, //Miljö
  Business, //Affärer
  Work, //Arbetsliv
  Home, //Hem
  NewsDirect //Kundnyheter
}

export enum EnumViewCounterFrequency {
  Daily,
  Weekly
}

export interface INewsDetails {
  isActive: boolean;
  profileId: string;
  phoneNumber: string;
  webAddress: string;
  email?: any;
  logo: string;
  fax: string;
  companyId: string;
  fB_PageId?: any;
  membershipSelectionId: string;
  nextNews: INextNewNews[];
  newsId: string;
  zBase32Id: string;
  orgId: string;
  orgName: string;
  officeId: string;
  title: string;
  metaTitle: string;
  ingress: string;
  bigNews: boolean;
  nativeNews: boolean;
  imageUrl?: any;
  titleNewsletter: string;
  ingressNewsletter: string;
  body: string;
  sanitizerBody: SafeHtml;
  tags: string;
  tagIds: string;
  category: string;
  statusId: EnumNewsStatus;
  statusName?: string;
  publishedDate: string;
  createdBy?: any;
  createdDate: string;
  modifiedBy?: any;
  modifiedDate: string;
  next: string;
  previous: string;
  nextTitle: string;
  previousTitle: string;
  publisherMedia: any[];
  images: INewsImage[];
  newsCategories: INewsCategory[];
  views: number;
  last5DayViews: number;
  linkToSupplier: string;
}

export interface INewsCategory {
  id: string;
  newsId: number;
  masterCategoryId: string;
  description: string;
  description_SV: string;
  weight?: number;
}

export interface INewsImage {
  mediaContentId: string;
  typeId: number;
  contentType: any;
  fileName: string;
  urlPath: string;
  thumbnailUrl: string;
  title?: any;
  caption?: any;
  altText?: any;
  width: number;
  height: number;
  embeddedHtml?: any;
  ownerUser?: any;
  urlAzureImgDirectory: string;
  urlAzureImgFileName: string;
  urlAzureThumbnailDirectory: string;
  urlAzureThumbnailFileName: string;
  urlPathAz: string;
  thumbnailPathAz: string;
  indexId: number;
  thumbnailWidth: number;
  thumbnailHeight: number;
  viewMode?: boolean;
  ratio: EImageRatio;
  groupId: string;
  priorityRatioInNewsFlow?: number;
  safeUrlAz?: SafeUrl;
  safeUrl?: SafeUrl;
  safeThumbnailAz?: SafeUrl;
  urlPath670x0: string;
  urlPath300x225: string;
  urlFallback670x0: string;

  safeUrlPath670x0: SafeUrl;
  safeUrlPath300x225: SafeUrl;
  safeUrlFallback670x0: SafeUrl;
}

export interface INextNewNews {
  zBase32Id: string;
  newsId: number;
  title: string;
  newsCategories: INewsCategory[];
}

export interface INewsListItem {
  newsId: number;
  zBase32Id: string;
  title: string;
  titleNewsletter: string;
  ingressNewsletter: string;
  ingress: string;
  officeId: string;
  orgName: string;
  officeName: string;
  imageUrl: string;
  thumbnailUrl: string;
  authorImageUrl?: any;
  prioritizedImageUrl: string;
  thumbnailPrioritizedImageUrl: string;
  orgImageLogo: string;
  authorName?: any;
  createdDate: string;
  publishedDate: string;
  modifiedDate: string;
  views: number;
  last5DayViews: number;
  weekViews: number;
  status: string;
  count: number;
  contentType: number;
  newsCategories: INewsCategory[];
  urlPath670x0: string;
  urlPath300x225: string;
  urlFallback670x0: string;

  clickUrl: string;
}

export enum EImageRatio {
  Custom = 1,
  Ratio4_3 = 2,
  Ratio16_9 = 3
}
