<form *ngIf="state != 'success'" #registerForm="ngForm" [formGroup]="form">
    <div class="control-wrapper mb-3">
      <label>Bostadsrättsföreningens namn*</label>
      <input matInput type="text" class="form-control" placeholder="Bostadsrättsföreningens namn*" autocomplete="off" formControlName="tenantName" name="tenantName" required />
      <mat-error *ngIf="form.controls['tenantName']?.touched && form.controls['tenantName']?.errors">Bostadsrättsföreningens namn krävs</mat-error>
    </div>
    <div class="control-wrapper mb-3">
      <label>Ort*</label>
      <input matInput type="text" class="form-control" placeholder="Ort*" autocomplete="off" formControlName="county" name="county" required />
      <mat-error *ngIf="form.controls['county']?.touched && form.controls['county']?.errors">Ort krävs</mat-error>
    </div>
    <div class="control-wrapper mb-3">
      <label>Namn</label>
      <input matInput type="text" class="form-control" placeholder="Namn" autocomplete="off" formControlName="name" name="name" />
    </div>

    <div class="control-wrapper mb-3">
      <label>E-postadress*</label>
      <input matInput type="text" class="form-control" placeholder="E-postadress*" autocomplete="off" formControlName="email" name="email" required />
      <mat-error *ngIf="form.controls['email']?.touched && form.controls['email']?.errors">Vänligen kontrollera e-postadressen</mat-error>
    </div>
    
    <div class="control-wrapper mb-3">
      <label>Meddelande</label>
      <textarea matInput placeholder="Meddelande" formControlName="message" name="message" class="form-control" autocomplete="off"></textarea>
    </div>

    <div class="control-wrapper mb-3 select-folder">
      <label class="mb-2">Välj område*</label>
      <div class="chk-wrapper">
        <input type="checkbox" class="checkbox-filter" formControlName="selectedMalardalen" id="chkMalardalen" autocomplete="off" />
        <label class="checkbox-filter-lbl" for="chkMalardalen">Mälardalen</label>
      </div>
      <div class="chk-wrapper">
        <input type="checkbox" class="checkbox-filter" formControlName="selectedVastraGotaland" id="chkVastraGotaland" autocomplete="off" />
        <label class="checkbox-filter-lbl" for="chkVastraGotaland">Västra Götaland</label>
      </div>
      <div class="chk-wrapper">
        <input type="checkbox" class="checkbox-filter" formControlName="selectedSkane" id="chkSkane" autocomplete="off" />
        <label class="checkbox-filter-lbl" for="chkSkane">Skåne</label>
      </div>
    </div>
    <div>
      <re-captcha formControlName="token"></re-captcha>
    </div>
    <div class="control-wrapper mb-3 mt-3">
      <div class="chk-wrapper">
        <input type="checkbox" class="checkbox-filter" formControlName="acceptTerm" id="chkTerm" autocomplete="off" />
        <label class="checkbox-filter-lbl term-lbl" for="chkTerm">
          Genom att skicka iväg min registrering samtycker jag till att de personuppgifter jag angett, liksom eventuella personuppgifter som härrör registreringen används av BRF-Mappen i syfte att
          behandla min registrering.
        </label>
      </div>
    </div> 
      <div class="modal-footer">
        <button class="btn btn-light" mat-raised-button (click)="close()">Avbryt</button>
        <button class="btn btn-primary" mat-raised-button (click)="onSubmit()" [disabled]="!isFormValid()">Registrera nu</button>
      </div>
    </form>

    <div *ngIf="state == 'success'" class="success-screen">
      <br />
      <h2 class="text-center"><b>Tack för din registrering!</b></h2>
      <h3 class="text-center">Vi har tagit emot din registrering av prenumeration på BRF-Nytt.</h3>
      <mat-icon>check_circle_outline</mat-icon>
      <div class="modal-footer">
        <button class="btn btn-light" mat-raised-button (click)="close()">Stäng</button>
      </div>
    </div>


