import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { SubscribeDialogComponent } from '../../../shared/modals/subscribe-dialog/subscribe-dialog.component';
import { BrowserService } from '../../browser.service';
import { HeaderService } from '../../header.services';
import { Unsubscriber } from '../../unsubscriber/unsubscriber';
import { environment } from './../../../../environments/environment';

@Component({
  selector: 'brf-news-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends Unsubscriber implements OnInit {
  searchField = new UntypedFormControl();
  shouldShowHeader = true;
  isNewsCategory: boolean = false;
  loginUrl: string = '/organisation/marketing/dashboard/news';

  constructor(private router: Router, private browserervice: BrowserService, private headerService: HeaderService, public dialog: MatDialog) {
    super();
    this.loginUrl = environment.brfDashboard + this.loginUrl;
  }

  ngOnInit(): void {
    if (this.browserervice.isbrowser) {
      this.addSubscription(
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(e => {
          this.isNewsCategory = location.pathname === '/' || location.pathname === '/nyheter' || location.pathname.startsWith('/nyheter/');
        })
      );
    }
    this.addSubscription(
      this.headerService.showHeaderSubject.subscribe(flag => {
        this.shouldShowHeader = flag;
      })
    );
  }

  search() {
    const obj = { keyword: this.searchField.value };
    this.router.navigateByUrl('/search', { state: obj });
  }

  openSubscribeModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {};
    this.dialog.open(SubscribeDialogComponent, dialogConfig);
  }

  checkReloadPage() {
    if (this.router.url == '/') {
      location.reload();
    }
  }

  // goToLogin() {
  //   if (this.browserervice.isbrowser) {
  //     setTimeout(() => {
  //       window.open(this.loginUrl, '_blank');
  //     });
  //   }
  // }
}
