import { Injectable } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { BrowserService } from '../browser.service';

@Injectable({
  providedIn: 'root'
})
export class SvvGoogleAnalyticsService {
  constructor(private $gaService: GoogleAnalyticsService, private browService: BrowserService) {}

  pageView(url: string, title: string) {
    if (this.browService.isbrowser) {
      if (window['isFirstLoad']) {
        //GA lib already send ping to it's server
        window['isFirstLoad'] = false;
      } else {
        this.$gaService.pageView(url, title);
      }
    }
  }
}
