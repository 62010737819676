import { environment } from './../../environments/environment';
import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { BrowserService } from './browser.service';

export interface ISeoConfig {
  title: string;
  metaTitle?: string;
  description: string;
  image: string;
  slug: string;
  keywords?: string;
  prerenderStatusCode?: string;
}

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  href = '';
  homepage = '';
  constructor(private titleService: Title, private meta: Meta, @Inject(DOCUMENT) private doc: any, @Inject(PLATFORM_ID) private platformId: any, private browserService: BrowserService) {
    this.homepage = environment.domain;
  }

  ConvertToPlainText(html: string, returnString: string): string {
    if (this.browserService.isbrowser) {
      const temp = document.createElement('div');
      temp.innerHTML = html;
      return temp.textContent || temp.innerText || returnString;
    }
    return returnString;
  }

  generateTags(configProvided: ISeoConfig) {
    // default values
    const config = {
      title: environment.seoTags.homeTitle + ' | ' + environment.seoTags.default,
      description: environment.seoTags.homeDescription,
      image: 'https://cdn.brfmappen.se/public/images/brf_news_social_logo.webp',
      slug: '',
      prerenderStatusCode: '200',
      ...configProvided
    };

    let metaTitle = config.metaTitle ? config.metaTitle : config.title;
    let title = metaTitle.replace(' från', ',');
    if (title.length == 0) title = environment.seoTags.default;

    this.titleService.setTitle(title === environment.seoTags.default ? title : title + ' | ' + environment.seoTags.default);
    this.meta.updateTag({ name: 'description', content: this.truncate(this.ConvertToPlainText(config.description, ''), 150, true) });

    /**** TWITTER ****/
    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({ name: 'twitter:site', content: '@brfmappen' });
    this.meta.updateTag({ name: 'twitter:title', content: metaTitle.replace(' från', ',') });
    this.meta.updateTag({ name: 'twitter:description', content: this.truncate(this.ConvertToPlainText(config.description, ''), 150, true) });
    this.meta.updateTag({ name: 'twitter:image', content: config.image });

    /**** OPEN GRAPH ****/
    this.meta.updateTag({ property: 'og:type', content: 'article' });
    this.meta.updateTag({ property: 'og:site_name', content: '@brfmappen' });
    this.meta.updateTag({ property: 'og:title', content: metaTitle.replace(' från', ',') });
    this.meta.updateTag({ property: 'og:description', content: this.truncate(this.ConvertToPlainText(config.description, ''), 150, true) });
    this.meta.updateTag({ property: 'og:image', content: config.image });
    this.meta.updateTag({ property: 'og:url', content: `${this.homepage}/${config.slug}` });

    this.meta.updateTag({ name: 'prerender-status-code', content: `${config.prerenderStatusCode}` });

    if (config.keywords) {
      this.meta.updateTag({ name: 'keywords', content: config.keywords });
    }
    window['prerenderReady'] = true;
    this.createLinkForCanonicalURL(config.slug);
  }

  createLinkForCanonicalURL(slug: string) {
    const link: HTMLLinkElement = this.doc.querySelector('link[rel=canonical]');
    this.href = this.homepage + '/' + slug;

    link.setAttribute('href', this.href);
  }

  truncate(str: string, n: number, useWordBoundary: boolean) {
    let singular: boolean;
    const tooLong = str.length > n;
    useWordBoundary = useWordBoundary || true;

    // Edge case where someone enters a ridiculously long string.
    str = tooLong ? str.substr(0, n - 1) : str;

    singular = str.search(/\s/) === -1 ? true : false;
    if (!singular) {
      str = useWordBoundary && tooLong ? str.substr(0, str.lastIndexOf(' ')) : str;
    }

    return tooLong ? str + ' ...' : str;
  }

  makeTitleUrlFriendly(title: string): string {
    let fixedTitle: string;
    fixedTitle = title
      .normalize('NFKD')
      .replace(/[^\w\s-]/gi, '')
      .replace(/ /g, '-')
      .replace(/_+/g, '-')
      .replace(/--/g, '-');
    return this.replaceUmlaute(fixedTitle).replace(/[^A-Za-z0-9-]/g, '');
  }

  replaceUmlaute(str) {
    const umlautMap = {
      Ü: 'UE',
      Ä: 'AE',
      Ö: 'OE',
      ü: 'ue',
      ä: 'ae',
      ö: 'oe',
      ß: 'ss'
    };
    return str
      .replace(/[\u00dc|\u00c4|\u00d6][a-z]/g, a => {
        const big = umlautMap[a.slice(0, 1)];
        return big.charAt(0) + big.charAt(1).toLowerCase() + a.slice(1);
      })
      .replace(new RegExp('[' + Object.keys(umlautMap).join('|') + ']', 'g'), a => umlautMap[a]);
  }

  isBotCrawler(userAgent: string) {
    let crawlerUserAgents = [
      'googlebot',
      'Yahoo! Slurp',
      'bingbot',
      'yandex',
      'baiduspider',
      'facebookexternalhit',
      'twitterbot',
      'rogerbot',
      'linkedinbot',
      'embedly',
      'quora link preview',
      'showyoubot',
      'outbrain',
      'pinterest/0.',
      'developers.google.com/+/web/snippet',
      'slackbot',
      'vkShare',
      'W3C_Validator',
      'redditbot',
      'Applebot',
      'WhatsApp',
      'flipboard',
      'tumblr',
      'bitlybot',
      'SkypeUriPreview',
      'nuzzel',
      'Discordbot',
      'Google Page Speed',
      'Qwantify',
      'pinterestbot',
      'Bitrix link preview',
      'XING-contenttabreceiver',
      'Chrome-Lighthouse',
      'TelegramBot',
      'SeznamBot',
      'prerender',
      'SiteCheckerBotCrawler'
    ];

    if (
      crawlerUserAgents.some(function (crawlerUserAgent) {
        return userAgent.toLowerCase().indexOf(crawlerUserAgent.toLowerCase()) !== -1;
      })
    )
      return true;

    return false;
  }
}
