import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CategoryItem } from '../models/supplier-search/supplier-aggregation.model';
import { ISupplierFilterParam, ISupplierFilterState } from '../models/supplier-search/supplier-filters.model';
import { ISuppliers, ISuppliersResult } from '../models/supplier-search/supplier-result.model';

@Injectable({
  providedIn: 'root'
})
export class SupplierSearchService {
  private stateParams: ISupplierFilterState;
  private itemClickedPos: any = 0;

  constructor(private httpClient: HttpClient) {
    this.resetStateParams();
  }

  search(page: number, pageSize: number, filter: ISupplierFilterParam): Observable<ISuppliersResult> {
    let querystring = `page=${page}&pageSize=${pageSize}`;
    return this.httpClient.post<ISuppliersResult>(`${environment.api}/suppliersearch/searches?${querystring}`, filter);
  }

  getAllCategoriesDetail(): Observable<CategoryItem[]> {
    return this.httpClient.get<CategoryItem[]>(`${environment.api}/suppliersearch/getallcategoriesdetail`);
  }

  getLatestPublishedDigitalSuppliers(pageSize: number = 5) {
    let querystring = `size=${pageSize}`;
    return this.httpClient.get<ISuppliers[]>(`${environment.api}/suppliersearch/latestPublishedDigitalProfiles?${querystring}`);
  }

  resetStateParams() {
    this.stateParams = {
      categories: [],
      employee: null,
      region: null,
      queryString: '',
      page: 0,
      pageSize: 10
    };
  }

  setStateParams(inputParam: ISupplierFilterState, page: number, pageSize: number) {
    this.stateParams.region = inputParam.region;
    this.stateParams.employee = inputParam.employee;
    this.stateParams.categories = inputParam.categories;
    this.stateParams.queryString = inputParam.queryString;
    this.stateParams.page = page;
    this.stateParams.pageSize = pageSize;
  }

  getStateParams() {
    return this.stateParams;
  }

  getItemClickedPos(){
    return this.itemClickedPos;
  }

  setItemClickedPos(pos: any){
    this.itemClickedPos = pos;
  }

}
