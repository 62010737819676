import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'brf-mappen-widget',
  templateUrl: './brf-mappen-widget.component.html',
  styleUrls: ['./brf-mappen-widget.component.scss']
})
export class BrfMappenComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
