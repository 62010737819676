import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BrowserService } from '../../../core/browser.service';
import { IExposure } from '../../models/exposure.model';

@Component({
  selector: 'brf-exposure-small',
  templateUrl: './exposure-small.component.html',
  styleUrls: ['./exposure-small.component.scss']
})
export class ExposureSmallComponent implements OnInit {
  @Input() exposures300x250: IExposure;
  @Output() clickEvent = new EventEmitter<IExposure>();
  mobileView = false;
  constructor(private browserService: BrowserService) {
    if (browserService.isbrowser) {
      this.browserService.isMobileScreenSubject.subscribe(flag => (this.mobileView = flag));
    }
  }

  ngOnInit(): void {}

  exposureClicked(exposure: IExposure) {
    this.clickEvent.next(exposure);
  }
}
