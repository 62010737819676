import { Observable, throwError } from 'rxjs';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';

export abstract class ServiceHelper {
  constructor() {}

  public extractString(response: string) {
    return response || '';
  }
  public extractStringData(response: string) {
    return JSON.parse(response) || {};
  }
  public extractData(response: HttpResponse<any>): any {
    const body = response;
    return body || {};
  }
  public handleError(error: HttpErrorResponse): Observable<any> {
    return throwError(error.error || 'Server error');
  }
  public handleErrorFull(error: HttpResponse<any>): Observable<any> {
    return throwError(error || 'Server error');
  }

  buildFormData(formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
      Object.keys(data).forEach(key => {
        this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
      });
    } else {
      const value = data == null ? '' : data;

      formData.append(parentKey, value);
    }
  }

  jsonToFormData(data) {
    const formData = new FormData();
    this.buildFormData(formData, data, null);
    return formData;
  }
}
