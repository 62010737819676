import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BrowserService } from '../browser.service';
declare var window: any;

export interface IPageviewLog {
  pageUrl: string;
  pageDomain: string;
  logTimestamp: Date;
}

@Injectable({
  providedIn: 'root'
})
export class PageviewlogService {
  constructor(private http: HttpClient, private browserService: BrowserService) {}

  // for now single pageview is captured.., batch will come soon
  capturePageview(pageurl: string) {
    const url = `${environment.api}/shared/pageviewed`;
    const payload: IPageviewLog[] = [];
    payload.push({
      logTimestamp: new Date(),
      pageUrl: pageurl,
      pageDomain: this.getHostname()
    });
    return this.http.post<any>(url, payload);
  }

  getHostname(): string {
    let baseUrl = '';
    if (this.browserService.isbrowser) {
      const parsedUrl = new URL(window?.location?.href);
      baseUrl = parsedUrl?.origin;
    }
    return baseUrl;
  }
}
