<footer>
  <div class="container" *ngIf="shouldShowFooter">
    <div id="sticky-footer" class="p-2">
      <div class="container d-none d-md-block d-lg-block d-xl-block">
        <div class="row">
          <div class="col-sm-4 d-flex align-items-center">
            <ul>
              <li>
                <a href="https://www.facebook.com/brfnyheter" target="_blank" aria-label="Facebook">
                  <img class="sticky-footer-icon" width="30" height="30" src="https://cdn.brfmappen.se/public/images/facebook.svg" alt="Facebook Link" />
                </a>
              </li>
            </ul>
          </div>
          <div class="col-sm-4 text-center col-sm-4 d-flex align-items-center justify-content-center read-news">
            <a style="color: #ffffff" routerLink="/las-brf-mappen">LÄS TIDNINGEN</a>
          </div>
          <div class="col-sm-4 d-flex align-items-center justify-content-end">
            <ul>
              <li><span (click)="openSubscribeModal()" class="btn btn-outline-light btn-sm">PRENUMERERA</span></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="container d-md-none d-lg-none d-xl-none">
        <div class="row">
          <div class="col-sm-12">
            <a routerLink="/hitta-leverantor" class="btn btn-outline-light w-100">HITTA LEVERANTÖR</a>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-5">
        <img class="img-responsive footer-logo" src="https://cdn.brfmappen.se/public/images/logo.svg" alt="Logo" />
      </div>
      <div class="col-md-7 external-logos-wrapper">
        <div class="row">
          <div class="col-md-6 com-logo-block">
            <a href="https://www.branschvinnare.se/" target="_blank">
              <img class="company-logo" src="https://cdn.brfmappen.se/public/images/Branschvinnare_Fastighetsmedia.webp" alt="Branschvinnare Fastighetsmedia logo" />
            </a>
          </div>
          <div class="col-md-3 com-logo-block">
            <a href="https://www.dnb.com/sv-se/om-bisnode/vad-vi-gor/vart-satt-att-se-pa-risk-och-kredit/kreditvarderingsmodell-foretag/" target="_blank">
              <img class="company-logo" src="https://cdn.brfmappen.se/public/images/AAA_Fastighetsmedia.webp" alt="AAA Fastighetsmedia logo" />
            </a>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 com-logo-block">
            <a href="https://www.climatecalc.eu/" target="_blank">
              <img class="company-logo" src="https://cdn.brfmappen.se/public/images/climatecalc.webp" alt="Climate Calc logo" />
            </a>
          </div>
          <div class="col-md-4 com-logo-block">
            <a href="https://fsc.org/" target="_blank">
              <img class="company-logo" src="https://cdn.brfmappen.se/public/images/fsc_logo.webp" alt="FSC logo" />
            </a>
          </div>
          <div class="col-md-2 com-logo-block">
            <a href="https://www.svanen.se/" target="_blank">
              <img class="company-logo" src="https://cdn.brfmappen.se/public/images/Trycksak_logo.webp" alt="Trycksak logo" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <p class="address">
          Fastighetsmedia i Ljusdal AB&nbsp;|&nbsp;Org.nr: 556795-5264&nbsp;|
          <a class="contact-link" href="https://fastighetsmedia.se/kontakt" target="_blank">Kontakt</a>&nbsp;|          
          <a class="contact-link" target="_blank" href="https://cdn.brfmappen.se/azure-prod/privacy_terms/Fastighetsmedia_-_Integritetspolicy_och_Anvandarvillkor.pdf">Integritetspolicy och användarvillkor</a>&nbsp;|
          BRF-Mappen © {{year}}&nbsp;|
          <span>v {{currentVersion}}&nbsp;</span>
          <span class="btn btn-default update-btn" (click)="update()" *ngIf="isDifferentVersion">Uppdatera</span>
        </p>
      </div>
    </div>
  </div>
</footer>
