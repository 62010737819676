import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ServiceHelper } from '../helpers/service.helper';
import { EmailMessageWithEntityId, IEmailMessage } from '../models/email.model';

@Injectable({
  providedIn: 'root'
})
export class MailService extends ServiceHelper {
  constructor(private httpClient: HttpClient) {
    super();
  }

  sendEmailWithType(email: IEmailMessage): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(`${environment.api}/email/pushemail2queue`, email, { headers });
  }

  sendMessageToQueueWithEntityIds(email: EmailMessageWithEntityId): Observable<any> {
    const headers = new HttpHeaders();
    let formData = this.jsonToFormData(email);

    return this.httpClient.post(`${environment.api}/email/push2queuewithentityids`, formData, { headers });
  }

  jsonToFormData(data) {
    const formData = new FormData();
    this.buildFormData(formData, data, null);
    return formData;
  }
}
