<div class="newsletter-wg">
  <div class="orange">Nyhetsbrev</div>
  <p class="description">
    Håll dig uppdaterad med de senaste
    <span>BRF-nyheterna</span>
  </p>
  <input type="text" class="form-control txt-control" placeholder="Din e-postadress" [formControl]="emailControl" (keyup)="$event.keyCode == 13 ? submit() : null" autocomplete="off" />

  <button class="btn btn-primary search-btn" (click)="submit()">PRENUMERERA</button>
</div>
