import { SafeHtml, SafeUrl } from '@angular/platform-browser';

export interface IExposure {
  id: string;
  zBase32Id: string;
  organisationId: string;
  officeId: string;
  officeName: string;
  orderId: string;
  orderItemId: string;
  orderItemvalue: number;
  format: string;
  placement: string;
  width: number;
  height: number;
  startDate: Date;
  endDate: Date;
  exposureStatusId: number;
  exposureStatus: string;
  viewCount: number;
  clickCount: number;
  isContentPresent: boolean;
  isMobileContentPresent: boolean;
  exposureTypeId: number;
  contentTypeId: EnumExposureContentType;

  desktopContentUrl?: string;
  mobileContentUrl?: string;
  desktopContentFallbackUrl?: string;
  mobileContentFallbackUrl?: string;

  desktopHTML5Content?: string;
  mobileHTML5Content?: string;

  safeDesktopContentUrl?: SafeUrl;
  safeMobileContentUrl?: SafeUrl;
  safeDesktopContentFallbackUrl?: SafeUrl;
  safeMobileContentFallbackUrl?: SafeUrl;

  safeDesktopHTML5Content?: SafeHtml;
  safeMobileHTML5Content?: SafeHtml;
  clickUrl: string;
  clickUrlMobile: string;
  exposureCategories: any[];
  createdOn: Date;
  modifiedOn: Date;
}

export interface IExposureLog {
  formatType: EnumExposureLogFormatType;
  id: string;
  clickUrl?: string;
}

export enum EnumExposureContentType {
  FILE = 1,
  HTML5 = 2
}

export enum EnumExposureLogFormatType {
  Exposure980x180 = 1,
  Exposure670x300 = 2,
  Exposure300x250 = 3
}
