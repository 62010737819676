import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ServiceHelper } from '../helpers/service.helper';
import { IExposure, IExposureLog } from '../models/exposure.model';

@Injectable({
  providedIn: 'root'
})
export class ExposureService extends ServiceHelper {
  private url = environment.api;

  constructor(private http: HttpClient) {
    super();
  }
  getExposures(format: string): Observable<IExposure[]> {
    return this.http.get<IExposure[]>(`${this.url}/exposure/active?format=${format}`);
  }
  
  postExposureViewLog(payload: IExposureLog[]): Observable<IExposure[]> {
    return this.http.post<any>(`${this.url}/exposure/viewed`, payload);
  }
  
  postExposureClickLog(payload: IExposureLog[]): Observable<IExposure[]> {
    return this.http.post<any>(`${this.url}/exposure/clicked`, payload);
  }
}
