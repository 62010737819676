<div class="search-sp" [ngClass]="{ 'no-border': isIframe }">
  <div class="filter-header" *ngIf="isMainSearchPage">
    <mat-icon class="filter-icon">filter_alt</mat-icon>
    <span class="filter-title">FILTRERA</span>
  </div>
  <div class="orange" *ngIf="!isMainSearchPage">Hitta leverantörer och entreprenörer till er BRF</div>
  <div class="list-cat" *ngIf="isMainSearchPage">
    <button class="btn btn-primary clear-btn" (click)="clearFilter()">Töm Filter</button>
    <!-- <div class="list-regions">
      <mat-chip-list #regChipList>
        <mat-chip class="regionChip" selected="true" *ngFor="let reg of curFilter.region" [selectable]="true" [removable]="true" (removed)="removeRegFilters(reg)">
          {{ reg.name }}
          <mat-icon matChipRemove class="regionChipRemove">cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </div> -->
    <div class="list-categories">
      <mat-chip-list #procChipList>
        <mat-chip class="catChip" selected="true" *ngFor="let cat of curFilter.categories" [selectable]="true" [removable]="true" (removed)="removeCatFilters(cat)">
          <span>{{ cat.description }}</span>
          <mat-icon matChipRemove class="catChipRemove">cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </div>
  </div>
  <input
    type="text"
    [(ngModel)]="queryString"
    class="form-control txt-control"
    *ngIf="!isMainSearchPage"
    placeholder="🔎︎ Sök leverantörer & produkter"
    name="search"
    (keyup.enter)="search()"
    autocomplete="off"
  />

  <mat-accordion class="multi-select-filters cat-filter" multi>
    <mat-expansion-panel>
      <mat-expansion-panel-header class="tab-header">
        <mat-panel-title><span class="header-text">Kategorier</span></mat-panel-title>
        <mat-icon *ngIf="curFilter.categories.length > 0" class="clear-cat" (click)="clearCategories($event)">delete</mat-icon>
      </mat-expansion-panel-header>
      <div class="opt-wrapper">
        <div class="chk-wrapper" *ngFor="let cat of aggCategories; let i = index">
          <input
            type="checkbox"
            class="checkbox-filter"
            [(ngModel)]="cat.isSelected"
            id="{{ 'catOpt_' + rndNum + '_' + i }}"
            (change)="onFilterCategoriesChange(cat, $event.target.checked)"
            autocomplete="off"
          />
          <label class="checkbox-filter-lbl" for="{{ 'catOpt_' + rndNum + '_' + i }}" matTooltip="{{ cat.description + ' [' + cat.count + ']' }}">{{ cat.description + ' [' + cat.count + ']' }}</label>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion class="multi-select-filters reg-filter" multi>
    <mat-expansion-panel>
      <mat-expansion-panel-header class="tab-header">
        <mat-panel-title>
          <span class="header-text">Regioner</span>
          <mat-icon *ngIf="curFilter.region != null" class="clear-reg" (click)="clearRegion($event)">delete</mat-icon>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="opt-wrapper">
        <div class="radio-wrapper">
          <mat-radio-group [(ngModel)]="curFilter.region" (change)="onFilterRegionsChange()">
            <mat-radio-button *ngFor="let reg of aggRegions" [value]="reg" matTooltip="{{ reg.description + ' [' + reg.count + ']' }}">
              {{ reg.description + ' [' + reg.count + ']' }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <!-- <div class="filter-control">
    <div class="fc-header">Regioner</div>
    <div class="fc-body">
      <brf-ddl-select [config]="regionsAggregationConfig" (selected)="filterChanged($event, 'region')"></brf-ddl-select>
    </div>
  </div> -->

  <!-- <div class="filter-control">
    <div class="fc-header">Antal anställda</div>
    <div class="fc-body">
      <brf-ddl-select [config]="employeeAggregationConfig" (selected)="filterChanged($event, 'employee')"></brf-ddl-select>
    </div>
  </div> -->

  <button class="btn btn-primary search-btn" (click)="search(true)" *ngIf="!isMainSearchPage">SÖK PROFFS</button>
  <div class="connect-lbl" *ngIf="!isMainSearchPage">
    <a href="https://fastighetsmedia.se/digital-profil" target="_blank">
      <mat-icon class="connect-icon">link</mat-icon>
      Anslut ditt företag
    </a>
  </div>
</div>
