import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VersionService {
  private url = environment.api;
  constructor(private http: HttpClient) {}

  getLatestVersion(): Observable<string> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.get(`${this.url}/version/getversion/2`, { headers: headers }).pipe(
      map((data: any) => {
        if (data && data.version) return data.version;
      })
    );
  }
}
