import { Subject } from 'rxjs';
import { Injectable, PLATFORM_ID, Inject } from '@angular/core';

@Injectable()
export class HeaderService {
  public stickyHeight = 0;
  public stickyHeightSubject = new Subject<number>();
  public showHeaderSubject = new Subject<boolean>();
  public showFooterSubject = new Subject<boolean>();

  constructor(@Inject(PLATFORM_ID) private platformId: any) {}

  setHeightForSticky(height = 0) {
    this.stickyHeight = height;
    this.stickyHeightSubject.next(this.stickyHeight);
  }

  showHeader(flag: boolean) {
    this.showHeaderSubject.next(flag);
  }

  showFooter(flag: boolean) {
    this.showFooterSubject.next(flag);
  }
}
