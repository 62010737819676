import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Unsubscriber } from '../../../core/unsubscriber/unsubscriber';
import { BrowserService } from '../../../core/browser.service';
import { AggEmployeeItem, AggRegionItem, AggCategoryItem } from '../../models/supplier-search/supplier-aggregation.model';
import { ISupplierFilterParam, ISupplierFilterState } from '../../models/supplier-search/supplier-filters.model';
import { SupplierSearchService } from '../../services/supplier-search.service';
import Utils from '../../utilities';
import { IDdlConfig } from '../ddl-select/ddl-select.component';

@Component({
  selector: 'brf-search-supplier',
  templateUrl: './search-supplier.component.html',
  styleUrls: ['./search-supplier.component.scss']
})
export class SearchSupplierComponent extends Unsubscriber implements OnInit, OnChanges {
  @Input() isMainSearchPage: boolean = false;
  @Input() isIframe: boolean = false;
  @Input() curFilter: ISupplierFilterState = {
    categories: [],
    region: null,
    employee: null,
    queryString: ''
  };

  @Input() aggCategories: AggCategoryItem[] = [];
  @Input() selectedCategoriesText: string[] = [];
  @Input() aggRegions: AggRegionItem[] = [];
  @Input() selectedRegionsText: string = '';
  //@Input() aggEmployees: AggEmployeeItem[] = [];
  @Output() onSearch = new EventEmitter<ISupplierFilterState>();

  queryString: string = '';

  regionsAggregationConfig: IDdlConfig;
  //employeeAggregationConfig: IDdlConfig;

  rndNum: number = Math.floor(Math.random() * 1000);

  constructor(private browserService: BrowserService, private router: Router, private supplierSearchService: SupplierSearchService, private domSanitizer: DomSanitizer) {
    super();
  }

  ngOnInit(): void {
    if (this.browserService.isbrowser) {
      var emptyParams: ISupplierFilterParam = {
        categoryIds: [],
        queryString: '',
        regionId: null,
        employeeId: null
      };
      this.addSubscription(
        this.supplierSearchService.search(1, 0, emptyParams).subscribe(result => {
          if (result) {
            this.aggCategories = result.skillsAggregation.sort((a, b) => {
              return Utils.compareObjects(a, b, 'description');
            });

            if (this.selectedCategoriesText && this.selectedCategoriesText.length > 0) {
              this.aggCategories.forEach((cat: AggCategoryItem) => {
                let sIndex = this.selectedCategoriesText.findIndex((cn: string) => cn.trim() == cat.description.trim());
                if (sIndex > -1) {
                  cat.isSelected = true;
                  this.curFilter.categories.push(cat);
                }
              });
            }

            if (this.selectedRegionsText && this.selectedRegionsText.length > 0) {
              let rIndex = result.regionsAggregation.findIndex(
                (rn: AggRegionItem) =>
                  rn.description.replace(' län', '').trim() == this.selectedRegionsText.replace(' län', '').trim() ||
                  rn.description.replace(' län', '').trim() == this.selectedRegionsText.replace(' län', '').trim() + 's'
              );
              if (rIndex > -1) {
                result.regionsAggregation[rIndex].isSelected = true;
                this.curFilter.region = result.regionsAggregation[rIndex];
              }
            }

            if (!this.isMainSearchPage) {
              this.aggRegions = result.regionsAggregation.sort((a, b) => {
                return Utils.compareObjects(a, b, 'description');
              });
              //this.setupRegionDdlConfig();

              // this.aggEmployees = result.employeesAggregation.sort((a, b) => {
              //   return Utils.compareObjects(a, b, 'noOfEmployees', parseInt);
              // });
              // this.setupEmployeeDdlConfig();
            } else {
              this.search(false);
            }
          }
        })
      );
    }
  }

  ngOnChanges(sc: SimpleChanges) {
    if (sc && sc.aggRegions && sc.aggRegions.currentValue && sc.aggRegions.currentValue != sc.aggRegions.previousValue) {
      if (sc.aggRegions.currentValue.length > 0) {
        this.aggRegions = sc.aggRegions.currentValue.sort((a, b) => {
          return Utils.compareObjects(a, b, 'description');
        });

        if (this.curFilter.region) {
          this.curFilter.region = this.aggRegions.find(r => r.region === this.curFilter.region.region);
        }
      } else if (this.curFilter.region) {
        this.curFilter.region.count = '0';
        this.aggRegions = [this.curFilter.region];
      }
      //this.setupRegionDdlConfig();
    }

    // if (sc && sc.aggEmployees && sc.aggEmployees.currentValue && sc.aggEmployees.currentValue != sc.aggEmployees.previousValue) {
    //   if (sc.aggEmployees.currentValue.length > 0) {
    //     this.aggEmployees = sc.aggEmployees.currentValue.sort((a, b) => {
    //       return Utils.compareObjects(a, b, 'noOfEmployees', parseInt);
    //     });

    //     if (this.curFilter.employee) {
    //       this.curFilter.employee = this.aggEmployees.find(r => r.noOfEmployees === this.curFilter.employee.noOfEmployees);
    //     }
    //   } else if (this.curFilter.employee) {
    //     this.curFilter.employee.count = '0';
    //     this.aggEmployees = [this.curFilter.employee];
    //   }
    //   this.setupEmployeeDdlConfig();
    // }
  }

  onFilterCategoriesChange(value: AggCategoryItem, isChecked: boolean) {
    if (isChecked) {
      this.curFilter.categories.push(value);
    } else {
      let rIndex = this.curFilter.categories.findIndex(item => {
        return item.category === value.category;
      });
      this.curFilter.categories.splice(rIndex, 1);
    }
    this.search(false);
  }

  onFilterRegionsChange() {
    this.search(false);
  }

  removeCatFilters(cat: AggCategoryItem) {
    cat.isSelected = false;
    this.onFilterCategoriesChange(cat, cat.isSelected);
  }

  search(isBtnClick: boolean = false) {
    let filter: ISupplierFilterState = {
      categories: this.curFilter.categories,
      region: this.curFilter.region,
      employee: this.curFilter.employee,
      queryString: this.queryString
    };
    if (this.onSearch.observers.length > 0) {
      this.onSearch.emit(filter);
    } else if (isBtnClick) {
      if (this.isIframe) {
        const url = this.router.serializeUrl(
          this.router.createUrlTree(['/hitta-leverantor'], {
            queryParams: {
              q: this.queryString,
              process: this.curFilter.categories.map(c => c.description).join('||'),
              region: this.curFilter.region && this.curFilter.region.description ? this.curFilter.region.description : '',
              page: 0,
              pageSize: 10
            }
          })
        );
        window.open(url, '_blank');
      } else {
        this.router.navigate(['/hitta-leverantor'], {
          queryParams: {
            q: this.queryString,
            process: this.curFilter.categories.map(c => c.description).join('||'),
            region: this.curFilter.region && this.curFilter.region.description ? this.curFilter.region.description : '',
            page: 0,
            pageSize: 10
          }
        });
      }
    }
  }

  clearFilter() {
    this.curFilter.region = null;
    this.curFilter.employee = null;

    this.curFilter.categories.forEach(c => {
      c.isSelected = false;
    });
    this.curFilter.categories = [];
    this.search(false);
  }

  clearCategories($event) {
    this.curFilter.categories.forEach(c => {
      c.isSelected = false;
    });
    this.curFilter.categories = [];
    this.search(false);
    $event.stopPropagation();
  }

  clearRegion($event) {
    this.curFilter.region = null;
    this.search(false);
    $event.stopPropagation();
  }

  defaultValue(type) {
    switch (type) {
      case 'region':
        if (this.curFilter.region) return this.curFilter.region[type];
        break;
      case 'noOfEmployees':
        if (this.curFilter.employee) return this.curFilter.employee[type];
        break;
    }
    return '-1';
  }

  // setupRegionDdlConfig() {
  //   this.regionsAggregationConfig = {
  //     displayField: 'description',
  //     data: this.aggRegions,
  //     valueField: 'region',
  //     labelText: 'Regions',
  //     defaultElementText: ' -- Välj Region -- ',
  //     count: 'count',
  //     defaultValue: this.defaultValue('region')
  //   };
  // }

  //setupEmployeeDdlConfig() {
  //   this.employeeAggregationConfig = {
  //     displayField: 'description',
  //     data: this.aggEmployees,
  //     valueField: 'noOfEmployees',
  //     labelText: 'No of Employees',
  //     defaultElementText: ' -- Välj antal anställda -- ',
  //     count: 'count',
  //     defaultValue: this.defaultValue('noOfEmployees')
  //   };
  //}

  // filterChanged(selectedOption, type: string) {
  //   switch (type) {
  //     case 'region':
  //       this.curFilter.region = selectedOption;
  //       break;
  //     case 'employee':
  //       this.curFilter.employee = selectedOption;
  //       break;
  //   }

  //   if (this.onSearch.observers.length > 0) {
  //     this.search(false);
  //   }
  // }
}
