import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './../material/material.module';
import { LoaderComponent } from './components/loader/loader.component';
import { ConfirmDialogComponent } from './modals/confirm-dialog/confirm-dialog.component';
import { SearchItemComponent } from './components/search-item/search-item.component';
import { FormatDatePipe } from './pipes/date-format.pipe';
import { ContactusDialogComponent } from './modals/contact-us/contactus-dialog.component';
import { SubscribeDialogComponent } from './modals/subscribe-dialog/subscribe-dialog.component';
import { CounterBrfComponent } from './components/counters-brf/counter-brf.component';
import { SearchSupplierComponent } from './components/search-supplier-widget/search-supplier.component';
import { DdlSelectComponent } from './components/ddl-select/ddl-select.component';
import { SendEmailDialogComponent } from './modals/send-email/send-email-dialog.component';
import { ExposureBannerComponent } from './components/exposure-banner/exposure-banner.component';
import { ExposureMediumComponent } from './components/exposure-medium/exposure-medium.component';
import { ExposureSmallComponent } from './components/exposure-small/exposure-small.component';
import { SuccessDialogComponent } from './modals/success-dialog/success-dialog.component';
import { RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings, RECAPTCHA_LANGUAGE, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { environment } from '../../environments/environment';
import { BrfListSupplierWidgetComponent } from './components/list-suppliers-widget/list-suppliers-widget.component';
import { counter } from '@fortawesome/fontawesome-svg-core';

@NgModule({
  declarations: [
    BreadcrumbComponent,
    LoaderComponent,
    ConfirmDialogComponent,
    SearchItemComponent,
    FormatDatePipe,
    ContactusDialogComponent,
    SubscribeDialogComponent,
    SendEmailDialogComponent,
    CounterBrfComponent,
    SearchSupplierComponent,
    DdlSelectComponent,
    SuccessDialogComponent,
    ExposureBannerComponent,
    ExposureMediumComponent,
    ExposureSmallComponent,
    BrfListSupplierWidgetComponent
  ],
  imports: [CommonModule, ReactiveFormsModule, FormsModule, MaterialModule, TranslateModule, RouterModule, RecaptchaModule, RecaptchaFormsModule],
  exports: [
    BreadcrumbComponent,
    LoaderComponent,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    TranslateModule,
    RouterModule,
    ConfirmDialogComponent,
    SearchItemComponent,
    FormatDatePipe,
    ContactusDialogComponent,
    SubscribeDialogComponent,
    SendEmailDialogComponent,
    CounterBrfComponent,
    SearchSupplierComponent,
    DdlSelectComponent,
    SuccessDialogComponent,
    ExposureBannerComponent,
    ExposureMediumComponent,
    ExposureSmallComponent,
    BrfListSupplierWidgetComponent
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.reCaptchaSiteKey
      } as RecaptchaSettings
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: 'sv' // use French language
    }
  ]
})
export class SharedModule {}
