import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../../app/material/material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ShareButtonsPopupModule } from 'ngx-sharebuttons/popup';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { IconsModule } from './fontawsome-custom.module';
import { BrowserService } from './browser.service';

@NgModule({
    declarations: [HeaderComponent, FooterComponent],
    imports: [CommonModule, IconsModule, RouterModule, TranslateModule, MaterialModule, ReactiveFormsModule, ShareButtonsPopupModule, ShareIconsModule],
    exports: [HeaderComponent, FooterComponent, RouterModule, TranslateModule],
    providers: [BrowserService]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule has already been loaded. You should only import Core modules in the AppModule only.');
    }
  }
}
