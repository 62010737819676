import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { BrowserService } from '../../../core/browser.service';
import { SeoService } from '../../../core/seo.service';
import { INewsListItem } from '../../../shared/models/news.model';
import { NewsService } from '../../services/news.service';
import { Router } from '@angular/router';
import { Base64 } from 'js-base64';
import { SvvGoogleAnalyticsService } from '../../../core/services/gg-analytics.service';
import { EnumExposureLogFormatType, IExposure, IExposureLog } from '../../../shared/models/exposure.model';
import { Unsubscriber } from '../../../core/unsubscriber/unsubscriber';
import { ExposureService } from '../../../shared/services/exposure.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'brf-news-news-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class NewsRedirectComponent extends Unsubscriber implements OnInit {
  exposures300x250: IExposure[];
  mostReadNews: INewsListItem[] = [];
  latestNews: INewsListItem[] = [];
  mobileView = false;

  constructor(
    private browserService: BrowserService,
    private titleService: Title,
    private router: Router,
    private newsService: NewsService,
    private seo: SeoService,
    private exposureService: ExposureService,
    private sanitizer: DomSanitizer,
    private ggAnalyticsService: SvvGoogleAnalyticsService,
    @Inject(DOCUMENT) private document: Document
  ) {
    super();
    if (this.browserService.isbrowser) {
      this.browserService.isMobileScreenSubject.subscribe(flag => (this.mobileView = flag));
      //this.mobileView = window.screen?.width <= 777;
    }
    let oldUrl = this.router.url;
    let urlParts = oldUrl.split('/');

    let qPos = urlParts[urlParts.length - 1].indexOf('?');
    if (qPos > 0) {
      var fields = urlParts[urlParts.length - 1].split('?');
      urlParts[urlParts.length - 1] = fields[0];
    }
    urlParts.splice(0, 1);
    oldUrl = urlParts.join('/');
    if (oldUrl == 'annonsera') {
      this.document.location.href = 'https://fastighetsmedia.se/annonsera';
    }

    this.addSubscription(
      this.newsService.getRedirectUrl(Base64.encode(oldUrl)).subscribe((result: any) => {
        if (result.url) {
          let parts = result.url.split('#');
          if (parts.length > 1) {
            this.router.navigate([parts[0]], { fragment: parts[1] });
          } else {
            this.router.navigate([result.url]);
          }
        } else {
          this.router.navigate(['/error/404'], {
            queryParams: { srcpath: this.router.url }
          });
        }
      })
    );
  }

  ngOnInit(): void {
    //This is redirect component we don't need ads
    //this.fetchExposures();
    if (this.browserService.isbrowser) {
      // this.handleLatestNews();
      // this.handleMostReadofTheWeekNews();
      this.handleSeo();
    }
  }

  fetchExposures() {
    this.exposures300x250 = [];
    this.addSubscription(
      this.exposureService.getExposures('300x250').subscribe((mainList: IExposure[]) => {
        if (mainList?.length > 0) this.setupExposure300x250(mainList);
      })
    );
  }

  setupExposure300x250(fromList: IExposure[], maxCount = 2) {
    var list = fromList.slice(0, maxCount);
    list.forEach(item => {
      item.safeDesktopHTML5Content = this.sanitizer.bypassSecurityTrustHtml(item?.desktopHTML5Content);
      item.safeMobileHTML5Content = this.sanitizer.bypassSecurityTrustHtml(item?.mobileHTML5Content);
    });
    this.exposures300x250 = list;
    this.exposureViewed(this.exposures300x250);
  }

  exposureClicked(exposure: IExposure) {
    if (exposure?.zBase32Id) {
      const payload: IExposureLog[] = [];
      const clickUrlExposure = this.mobileView ? exposure?.clickUrlMobile : exposure?.clickUrl;
      let format: EnumExposureLogFormatType;
      if (exposure.format == '980x180') format = EnumExposureLogFormatType.Exposure980x180;
      else if (exposure.format == '670x300') format = EnumExposureLogFormatType.Exposure670x300;
      else if (exposure.format == '300x250') format = EnumExposureLogFormatType.Exposure300x250;

      payload.push({
        formatType: format,
        id: exposure.zBase32Id,
        clickUrl: clickUrlExposure
      });
      this.addSubscription(
        this.exposureService.postExposureClickLog(payload).subscribe(x => {
          if (this.browserService.isbrowser) {
            setTimeout(() => {
              window.open(clickUrlExposure, '_blank');
            });
          }
        })
      );
    }
  }

  exposureViewed(exposures: IExposure[]) {
    if (exposures?.length > 0) {
      const payload: IExposureLog[] = [];

      exposures.forEach(elem => {
        let format: EnumExposureLogFormatType;
        if (elem.format == '980x180') format = EnumExposureLogFormatType.Exposure980x180;
        else if (elem.format == '670x300') format = EnumExposureLogFormatType.Exposure670x300;
        else if (elem.format == '300x250') format = EnumExposureLogFormatType.Exposure300x250;

        payload.push({
          formatType: format,
          id: elem.zBase32Id,
          clickUrl: this.mobileView ? elem?.clickUrlMobile : elem?.clickUrl
        });
      });
      this.addSubscription(this.exposureService.postExposureViewLog(payload).subscribe());
    }
  }

  handleSeo = () => {
    let searchedText = 'Search text';
    this.titleService.setTitle(searchedText + '| BRF-Mappen');
    this.seo.generateTags({
      title: 'BRF-Mappen',
      description: searchedText,
      image: 'https://cdn.brfmappen.se/public/images/brf_news_social_logo.webp',
      slug: 'redirect',
      prerenderStatusCode: '301'
    });
    this.ggAnalyticsService.pageView(this.router.url, 'BRF-Mappen');
  };
}
