<div style="text-decoration: none; color: #000" *ngIf="exposures300x250">
  <ng-container *ngIf="!mobileView">
    <div style="padding: 0 calc(50% - 150px); margin: 5px 0; font-size: 10pt; color: #58595b; text-transform: uppercase; text-align: center">Annons</div>
    <div class="" style="display: flex">
      <div class="a-ad-banner-300x250" (click)="exposureClicked(exposures300x250)" *ngIf="exposures300x250">
        <div *ngIf="exposures300x250?.contentTypeId == 2" class="ad-banner-300x250" [innerHTML]="exposures300x250?.safeDesktopHTML5Content">Advertisement</div>
        <img *ngIf="exposures300x250?.contentTypeId == 1" class="ad-banner-300x250 img-responsive" width="100%" [src]="exposures300x250?.desktopContentUrl" alt="Banner" />
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="mobileView">
    <div style="padding: 0 calc(50% - 150px); margin: 5px 0; font-size: 10pt; color: #58595b; text-transform: uppercase; text-align: center">Annons</div>
    <div style="padding: 0 calc(50% - 150px)">
      <div class="a-ad-banner-300x250" (click)="exposureClicked(exposures300x250)">
        <div *ngIf="exposures300x250?.contentTypeId == 2" class="ad-banner-300x250" [innerHTML]="exposures300x250?.safeMobileHTML5Content">Advertisement</div>
        <img *ngIf="exposures300x250?.contentTypeId == 1" class="ad-banner-300x250 img-responsive" style="margin-bottom: 0" width="100%" [src]="exposures300x250?.mobileContentUrl" alt="Banner" />
      </div>
    </div>
  </ng-container>
</div>
