import { Directive, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

// TODO: Add Angular decorator.
@Directive()
export class Unsubscriber implements OnDestroy {
  private subscriptions: Subscription[] = [];
  private destroyCallBack: () => void = null;

  addSubscription(subscription: Subscription | Subscription[]) {
    if (Array.isArray(subscription)) {
      this.subscriptions.push(...subscription);
    } else {
      this.subscriptions.push(subscription);
    }
  }

  addDestroyCallBack(callback: () => void) {
    this.destroyCallBack = callback;
  }

  unsubscribe() {
    this.subscriptions.forEach(subscription => {
      if (subscription && subscription != null && subscription != undefined) {
        subscription.unsubscribe();
      }
    });
  }

  ngOnDestroy() {
    if (this.destroyCallBack != null && this.destroyCallBack != undefined) {
      this.destroyCallBack();
    }
    this.unsubscribe();
  }
}
