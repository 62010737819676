export default class Utils {
  public static stringFormat = (str: string, ...args: string[]) => str.replace(/{(\d+)}/g, (match, index) => args[index] || '');
  public static compareObjects(object1, object2, key, parser = null) {
    const obj1 = parser ? parser(object1[key].toUpperCase()) : object1[key].toUpperCase();
    const obj2 = parser ? parser(object2[key].toUpperCase()) : object2[key].toUpperCase();

    if (obj1 < obj2) {
      return -1;
    }
    if (obj1 > obj2) {
      return 1;
    }
    return 0;
  }
  public static removeSpecialCharacter(input: string) {
    return input.replace(/[&|'"^=?<>():\{\}\[\]\\\/]/gi, '');
  }
}
